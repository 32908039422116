import { Country } from '@backmarket/http-api/src/standards/Country'

import { type State } from '../types/state'

type States = {
  [country: string]: State[]
}

export const STATES: States = {
  [Country.US]: [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska', forbidShipping: true },
    { value: 'AS', label: 'American Samoa', forbidShipping: true },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District Of Columbia' },
    {
      value: 'FM',
      label: 'Federated States Of Micronesia',
      forbidShipping: true,
    },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'GU', label: 'Guam', forbidShipping: true },
    { value: 'HI', label: 'Hawaii', forbidShipping: true },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MH', label: 'Marshall Islands', forbidShipping: true },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    {
      value: 'MP',
      label: 'Northern Mariana Islands',
      forbidShipping: true,
    },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PW', label: 'Palau', forbidShipping: true },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'PR', label: 'Puerto Rico', forbidShipping: true },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VI', label: 'Virgin Islands', forbidShipping: true },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
  ],
  [Country.JP]: [
    { value: '北海道', label: '北海道' },
    { value: '青森県', label: '青森県' },
    { value: '岩手県', label: '岩手県' },
    { value: '宮城県', label: '宮城県' },
    { value: '秋田県', label: '秋田県' },
    { value: '山形県', label: '山形県' },
    { value: '福島県', label: '福島県' },
    { value: '茨城県', label: '茨城県' },
    { value: '栃木県', label: '栃木県' },
    { value: '群馬県', label: '群馬県' },
    { value: '埼玉県', label: '埼玉県' },
    { value: '千葉県', label: '千葉県' },
    { value: '東京都', label: '東京都' },
    { value: '神奈川県', label: '神奈川県' },
    { value: '新潟県', label: '新潟県' },
    { value: '富山県', label: '富山県' },
    { value: '石川県', label: '石川県' },
    { value: '福井県', label: '福井県' },
    { value: '山梨県', label: '山梨県' },
    { value: '長野県', label: '長野県' },
    { value: '岐阜県', label: '岐阜県' },
    { value: '静岡県', label: '静岡県' },
    { value: '愛知県', label: '愛知県' },
    { value: '三重県', label: '三重県' },
    { value: '滋賀県', label: '滋賀県' },
    { value: '京都府', label: '京都府' },
    { value: '大阪府', label: '大阪府' },
    { value: '兵庫県', label: '兵庫県' },
    { value: '奈良県', label: '奈良県' },
    { value: '和歌山県', label: '和歌山県' },
    { value: '鳥取県', label: '鳥取県' },
    { value: '島根県', label: '島根県' },
    { value: '岡山県', label: '岡山県' },
    { value: '広島県', label: '広島県' },
    { value: '山口県', label: '山口県' },
    { value: '徳島県', label: '徳島県' },
    { value: '香川県', label: '香川県' },
    { value: '愛媛県', label: '愛媛県' },
    { value: '高知県', label: '高知県' },
    { value: '福岡県', label: '福岡県' },
    { value: '佐賀県', label: '佐賀県' },
    { value: '長崎県', label: '長崎県' },
    { value: '熊本県', label: '熊本県' },
    { value: '大分県', label: '大分県' },
    { value: '宮崎県', label: '宮崎県' },
    { value: '鹿児島県', label: '鹿児島県' },
    { value: '沖縄県', label: '沖縄県' },
  ],
  [Country.IT]: [
    { value: 'AG', label: 'Agrigento' },
    { value: 'AL', label: 'Alessandria' },
    { value: 'AN', label: 'Ancona' },
    { value: 'AO', label: 'Aosta' },
    { value: 'AR', label: 'Arezzo' },
    { value: 'AP', label: 'Ascoli Piceno' },
    { value: 'AT', label: 'Asti' },
    { value: 'AV', label: 'Avellino' },
    { value: 'BA', label: 'Bari' },
    { value: 'BT', label: 'Barletta-Andria-Trani' },
    { value: 'BL', label: 'Belluno' },
    { value: 'BN', label: 'Benevento' },
    { value: 'BG', label: 'Bergamo' },
    { value: 'BI', label: 'Biella' },
    { value: 'BO', label: 'Bologna' },
    { value: 'BZ', label: 'Bolzano' },
    { value: 'BS', label: 'Brescia' },
    { value: 'BR', label: 'Brindisi' },
    { value: 'CA', label: 'Cagliari' },
    { value: 'CL', label: 'Caltanissetta' },
    { value: 'CB', label: 'Campobasso' },
    { value: 'CI', label: 'Carbonia-Iglesias' },
    { value: 'CE', label: 'Caserta' },
    { value: 'CT', label: 'Catania' },
    { value: 'CZ', label: 'Catanzaro' },
    { value: 'CH', label: 'Chieti' },
    { value: 'CO', label: 'Como' },
    { value: 'CS', label: 'Cosenza' },
    { value: 'CR', label: 'Cremona' },
    { value: 'KR', label: 'Crotone' },
    { value: 'CN', label: 'Cuneo' },
    { value: 'EN', label: 'Enna' },
    { value: 'FM', label: 'Fermo' },
    { value: 'FE', label: 'Ferrara' },
    { value: 'FI', label: 'Firenze' },
    { value: 'FG', label: 'Foggia' },
    { value: 'FC', label: 'Forlì-Cesena' },
    { value: 'FR', label: 'Frosinone' },
    { value: 'GE', label: 'Genova' },
    { value: 'GO', label: 'Gorizia' },
    { value: 'GR', label: 'Grosseto' },
    { value: 'IM', label: 'Imperia' },
    { value: 'IS', label: 'Isernia' },
    { value: 'SP', label: 'La Spezia' },
    { value: 'AQ', label: "L'Aquila" },
    { value: 'LT', label: 'Latina' },
    { value: 'LE', label: 'Lecce' },
    { value: 'LC', label: 'Lecco' },
    { value: 'LI', label: 'Livorno' },
    { value: 'LO', label: 'Lodi' },
    { value: 'LU', label: 'Lucca' },
    { value: 'MC', label: 'Macerata' },
    { value: 'MN', label: 'Mantova' },
    { value: 'MS', label: 'Massa-Carrara' },
    { value: 'MT', label: 'Matera' },
    { value: 'ME', label: 'Messina' },
    { value: 'MI', label: 'Milano' },
    { value: 'MO', label: 'Modena' },
    { value: 'MB', label: 'Monza e della Brianza' },
    { value: 'NA', label: 'Napoli' },
    { value: 'NO', label: 'Novara' },
    { value: 'NU', label: 'Nuoro' },
    { value: 'OT', label: 'Olbia-Tempio' },
    { value: 'OR', label: 'Oristano' },
    { value: 'PD', label: 'Padova' },
    { value: 'PA', label: 'Palermo' },
    { value: 'PR', label: 'Parma' },
    { value: 'PV', label: 'Pavia' },
    { value: 'PG', label: 'Perugia' },
    { value: 'PU', label: 'Pesaro e Urbino' },
    { value: 'PE', label: 'Pescara' },
    { value: 'PC', label: 'Piacenza' },
    { value: 'PI', label: 'Pisa' },
    { value: 'PT', label: 'Pistoia' },
    { value: 'PN', label: 'Pordenone' },
    { value: 'PZ', label: 'Potenza' },
    { value: 'PO', label: 'Prato' },
    { value: 'RG', label: 'Ragusa' },
    { value: 'RA', label: 'Ravenna' },
    { value: 'RC', label: 'Reggio Calabria' },
    { value: 'RE', label: 'Reggio Emilia' },
    { value: 'RI', label: 'Rieti' },
    { value: 'RN', label: 'Rimini' },
    { value: 'RM', label: 'Roma' },
    { value: 'RO', label: 'Rovigo' },
    { value: 'SA', label: 'Salerno' },
    { value: 'VS', label: 'Medio Campidano' },
    { value: 'SS', label: 'Sassari' },
    { value: 'SV', label: 'Savona' },
    { value: 'SI', label: 'Siena' },
    { value: 'SR', label: 'Siracusa' },
    { value: 'SO', label: 'Sondrio' },
    { value: 'TA', label: 'Taranto' },
    { value: 'TE', label: 'Teramo' },
    { value: 'TR', label: 'Terni' },
    { value: 'TO', label: 'Torino' },
    { value: 'OG', label: 'Ogliastra' },
    { value: 'TP', label: 'Trapani' },
    { value: 'TN', label: 'Trento' },
    { value: 'TV', label: 'Treviso' },
    { value: 'TS', label: 'Trieste' },
    { value: 'UD', label: 'Udine' },
    { value: 'VA', label: 'Varese' },
    { value: 'VE', label: 'Venezia' },
    { value: 'VB', label: 'Verbano-Cusio-Ossola' },
    { value: 'VC', label: 'Vercelli' },
    { value: 'VR', label: 'Verona' },
    { value: 'VV', label: 'Vibo Valentia' },
    { value: 'VI', label: 'Vicenza' },
    { value: 'VT', label: 'Viterbo' },
  ],
  [Country.AU]: [
    { value: 'ACT', label: 'Australian Capital Territory' },
    { value: 'NSW', label: 'New South Wales' },
    { value: 'NT', label: 'Northern Territory' },
    { value: 'QLD', label: 'Queensland' },
    { value: 'SA', label: 'South Australia' },
    { value: 'TAS', label: 'Tasmania' },
    { value: 'VIC', label: 'Victoria' },
    { value: 'WA', label: 'Western Australia' },
  ],
}
